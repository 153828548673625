import React from "react"
import { EventCard, Layout, SEO } from "../../components"
import { graphql } from "gatsby"
import { titleWithoutPipe } from "../../utils/dom"
import { SUB_NAV_EVENTS_LINKS } from "../../utils/links"

export default function CorporativeEventsPage({ data }) {
  const events = data.allShopifyCollection.edges
  return (
    <Layout showSubMenu={true} currentLink="Eventos" links={SUB_NAV_EVENTS_LINKS}>
      <SEO title={`Eventos Corporativos`} />
      <section className="products">
        {events.map((ev, idx) => (
          <EventCard
            key={idx}
            title={titleWithoutPipe(ev.node.title)}
            image={ev.node.image}
            link={`corporativos/${ev.node.handle}`}
          />
        ))}
      </section>
    </Layout>
  )
}

export const query = graphql`
  query GET_CORPORATIVE_EVENTS {
    allShopifyCollection(filter: { title: { regex: "/CORPORATIVO/", ne: "EVENTOS CORPORATIVOS" } }) {
      edges {
        node {
          title
          description
          handle
          image {
            id
            localFile {
              childImageSharp {
                fluid(maxWidth: 920) {
                  originalName
                  srcSetWebp
                  tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`
